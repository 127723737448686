.w-list-unstyledh {

    padding-left: 0;
    list-style: none;
}

.complex_line_itemh {
    background-color: #f0f0f0;
    justify-content: space-around;
    align-items: center;

    margin-top: 4px;
    margin-bottom: 4px;
    padding: 8px 1%;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
}

.complex_line_itemh {
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 2% 1.4% 2% .5%;
    font-size: 3.6vw;
    line-height: 3.6vw;
}

.complex_line_blockh {
    width: 11%;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.complex_line_blockh.timingh {
    width: 31%;
}

.complex_line_blockh.cardh {
    width: 6%;
    display: flex;
}

.complex_line_blockh.timingh {
    width: 28%;
    justify-content: center;
    display: flex;
}

.complex_line_blockh.sumh {
    width: 26%;
    display: flex;
}

.complex_line_blockh.videoh {
    width: 10%;
    display: flex;
}

.complex_line_blockh.limith {
    width: 18%;
}

ul {
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

ol {
    margin-top: 0;
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
}

li {
    margin-bottom: 5px;
}

.complex_stiky_titleh {
    height: 9vw;
    padding-left: 1%;
    padding-right: 2%;
    font-size: 5.8vw;
    font-weight: 700;
    line-height: 6vw;
}

.complex_stiky_titleh {
    z-index: 1000;
    color: #fff;
    background-color: #009647;
    align-items: center;
    padding: .8% 1%;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    display: flex;
    position: relative;
    justify-content:space-around;
}


img {
    display: inline-block;
    width: auto;
    max-width: 100%;
    min-width: auto;
}

.complex_stiky_titleh {
    height: 9vw;
    padding-left: 1%;
    padding-right: 2%;
    font-size: 5.8vw;
    font-weight: 700;
    line-height: 6vw;
}
.comlex_title_cardh {
    width: 4.6vw;
    height: 6vw;
    border-radius: 3px;
    padding-left: .8vw;
    padding-right: .8vw;
  }

  .comlex_title_cardh.limith {
    width: 30px;
    height: 34px;
    padding-left: 1.3vw;
    padding-right: 1.3vw;
  }
  .complex_line_blockh.timing_limith {
    width: 42%;
    font-size: 4.2vw;
    line-height: 5vw;
  }
  .stiky_title_iconh.calendarh {
    width: 4.8vw;
  }