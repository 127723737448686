.cards-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50.92593vw;
    left: 0;
    right: 0;
    margin: auto;
    padding-bottom: 0.64815vw;
  }
.cards-list__card {
    width: 9vw;
    height: 10.27778vw;
    margin-top: 2.87037vw;
    font-size: 4.44444vw;
    line-height: 10.27778vw;
    color: #006F00;
    background: #BFBFC6;
    -webkit-border-radius: .9259vw;
    border-radius: .9259vw;
    cursor: pointer;
    text-align: center;
    margin-right: 3.24074vw;
  }
  .cards-list__card.active {
    color: #FFFEFE;
    background: #006F00;
  }

.playing-cards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 55.55556vw;
    left: 0;
    right: 0;
    margin: auto;
    padding-top: 2.59259vw;
    padding-bottom: 4.62963vw;
  }
  
  .playing-cards__card {
    position: relative;
    overflow: hidden;
    width: 10.27778vw;
    height: 14.62963vw;
    -webkit-border-radius: 0.92593vw;
    border-radius: 0.92593vw;
    margin-right: 3.98148vw;
    margin-top: 2.87037vw;
    background: #E1E1E1;
    border: 0.09259vw solid #B1B1B1;
  }
  
  .playing-cards__card img {
    width: 10.27778vw;
    height: 14.62963vw;
  }
  
  .playing-cards__card:nth-of-type(4n+1) {
    margin-right: 0;
  }
  
  .small-content-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 53.7037vw;
    left: 0;
    right: 0;
    margin: auto;
    direction: ltr;
  }
  .playing-cards__row .playing-cards__card {
    margin-right: 1.38889vw;
    margin-top: 1.48148vw;
  }
  
  .playing-cards__row {
    width: 100%;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  
  .playing-cards__row .playing-cards__container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    direction: ltr;
    margin-left: 8.5vw;
  }
  
  .playing-cards__row .playing-cards__icon {
    position: absolute;
    width: 7.59259vw;
    height: 7.59259vw;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #BFBFC6;
    background-image: url("../../Assets/ico/playing-card-icon.png");
    background-position: center center;
    -webkit-background-size: 4.07407vw 3.98148vw;
    background-size: 4.07407vw 3.98148vw;
    background-repeat: no-repeat;
  }
  
  .playing-cards__row .playing-cards__icon.active {
    background-color: #00994D;
  }

  .general {
    color: #fff;
    background: #40c167;
    position: fixed;
    bottom: 10px;
    width: 240px;
    left: calc(50% - 120px);
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    border-radius: 20px;
    z-index: 10;
    box-shadow: 3px 4px 8px #8a8a8a;
    font-family: OpenSansHebrew-Regular;
  }
  .generalsend {
    color: #fff;
    background: #ff1100;
    /* position: fixed; */
    bottom: 10px;
    width: 240px;
    left: calc(50% - 120px);
    height: 40px;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    border-radius: 20px;
    z-index: 10;
    box-shadow: 3px 4px 8px #8a8a8a;
    font-family: OpenSansHebrew-Regular;
  }
  .generalsend.ok{
    background: #40c167;
  }
  .generalt {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;;
  }
  .icon {
    display: block;;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .trash {
    height: 24px;
    width: 20px;
    background-image: url('../../Assets/mobile/img/delete_icone.png');
  }
  .mrow {
    display: flex;
    justify-content: center;
    border: 3px solid #fff;
    border-radius: 5px;
    padding: 8px 0;
    gap: 8px;
  }
  
  .mrow + .mrow {
    margin-top: 4px;
  }
  .cards {
    display: flex;
    gap: 4px;
    flex-direction: row-reverse;
  }
  .card {
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .card0.primary {
    background-image: url("../../Assets/mobile/img/spades.svg");
  }
  
  .card1.primary {
    background-image: url("../../Assets/mobile/img/hearts.svg");
  }
  
  .card2.primary {
    background-image: url("../../Assets/mobile/img/diamonds.svg");
  }
  
  .card3.primary {
    background-image: url("../../Assets/mobile/img/clubs.svg");
  }
  
  .card0.outline {
    background-image: url("../../Assets/mobile/img/icone-pick.png");
  }
  
  .card1.outline {
    background-image: url("../../Assets/mobile/img/icone-chev.png");
  }
  
  .card2.outline {
    background-image: url("../../Assets/mobile/img/icone-buba.png");
  }
  
  .card3.outline {
    background-image: url("../../Assets/mobile/img/icone-tref.png");
  }

.item {
  height: 56px;
  width: 45px;
  opacity: 0.7;
  border-radius: 5px;
  color: #000;
  font-size: 26px;
  position: relative;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: aliceblue;
}

.item.active {
  opacity: 1;
}

.item.selected {
  box-shadow: 0 0 0 2px #5470e9;
}


.cardp {
  width: 32px;
  height: 32px;
}
.magic {
  background-image: url('../../Assets/mobile/img/magic-wand.svg');
  background-size: 85%;
  height: 24px;
  width: 24px;
}

.white .magic {
  background-image: url('../../Assets/mobile/img/magic-wand-white.svg');
}
.small-content-table {
  overflow: hidden;
  width: 70vw;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 2.68519vw;
  border: 0.09259vw solid #006F00;
  -webkit-border-radius: 0.92593vw;
  border-radius: 0.92593vw;
}
.small-content-table__row_title {
  background: #009300;
}
.small-content-table__row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background-color: aliceblue;
}
.small-content-table__rowt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.small-content-table__row_title .small-content-table__cell {
  height: 8.14815vw;
  line-height: 8.14815vw;
  color: #FFFFFF;
}
.small-content-table__cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 6.85185vw;
  border-left: 0.09259vw solid #009300;
  text-align: center;
  color: #005000;
  font-size: 3.14815vw;
  font-family: 'OpenSansHebrew-Bold', sans-serif;
}
.small-content-table__cell p {
  margin-bottom: 0;
}
.card-summary-additional__info_title {
  margin-right: 5vw;
  display: inline-block;
  min-height: 6vw;
  max-width: 100%;
  font-family: "OpenSansHebrew-Regular", sans-serif;
  font-size: 4.7037vw;
  line-height: 1vw;
  color: #ffffff;
}
.card-summary-additional {
  /*-- Positioning --*/
  position: relative;
  right: 0;
  left: 0;
  /*-- Box model --*/
  width: 70vw;
  min-height: 11.1111vw;
  margin: auto;
  padding-right: 4.3519vw;
  padding-left: 4.3519vw;
}
.card-summary-additional__info {
  /*-- Box model --*/
  display: inline-block;
  min-height: 6vw;
  max-width: 100%;
  /*-- Typography --*/
  font-family: "OpenSansHebrew-Regular", sans-serif;
  font-size: 3.7037vw;
  line-height: 6vw;
  color: #979797;
  float: right;
}
.card-summary-additional__info_left {
  /*-- Box model --*/
  display: table;
  float: left;
  margin: auto;
}
.card-summary-additional.green-mod .card-summary-additional__info .bold {
  color: #ffffff;
}

.card-summary-total {
  /*-- Positioning --*/
  position: relative;
  right: 0;
  left: 0;
  /*-- Box model --*/
  width: 72vw;
  min-height: 9.537vw;
  margin: 0 auto 0;
  padding-left: 4.1667vw;
  padding-right: 4.1667vw;
  border-top: 0.2vw solid rgb(255, 255, 255);
  /*-- Formalization --*/
}
.card-summary-total__name {
  /*-- Box model --*/
  display: inline-block;
  min-height: 9.537vw;
  max-width: 55.5556vw;
  /*-- Typography --*/
  line-height: 9.537vw;
}

.card-summary-total__name p {
  /*-- Typography --*/
  font-family: "OpenSansHebrew-Regular", sans-serif;
  font-size: 3.7037vw;
  color: #979797;
}

.card-summary-total__name p .bold {
  /*-- Typography --*/
  font-family: "OpenSansHebrew-Bold", sans-serif;
  color: #ffffff;
}

.card-summary-total__price {
  /*-- Positioning --*/
  position: absolute;
  left: 4.1667vw;
  /*-- Box model --*/
  display: inline-block;
  height: 9.537vw;
  line-height: 9.537vw;
  /*-- Typography --*/
  font-size: 3.7037vw;
  color: #ffffff;
}
.cards-list-rav-chance{
  width : 30vw;
}

/*/////////////////////////////////////////////////*/
.kekeyboard {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  transition: transform .3s;
  transform: translateY(100%);
  direction: ltr;
}

.open {
  transform: translateY(0);
}
.keheader {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  background: #453e3e;
}
.keleft {
  display: flex;
}
.keactions {
  display: flex;
  gap: 8px;
  margin-right: 12px;
}
.kegenerala {
  border: none;
  background-color: transparent;
  outline: none;
  /*padding: 0;*/
}
.dark-outline {
  border-radius: 50%;
  border: 2px solid #e5e3e3;
  color: #e5e3e3;
}
.keicon {
  display: block;;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.trash {
  height: 24px;
  width: 20px;
  background-image: url('../../Assets/mobile/img/delete_icone.png');
}
.magic {
  background-image: url('../../Assets/mobile/img/magic-wand.svg');
  background-size: 85%;
  height: 24px;
  width: 24px;
}
.keswitcher {
  background-color: #47a147;
  border-radius: 16px;
  padding: 2px;
  display: flex;
  gap: 4px;
}

.skewitcherOption {
  text-shadow: 2px 1px 2px #666;
  color: #fff;
  font-family: OpenSansHebrew-Bold;
  letter-spacing: 1px;
  font-size: 13px;
  width: 70px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.keswitcherOption.active {
  color: #232323;
  background-color: #e5e3e3;
  text-shadow: 1px 1px 1px #999;
}


.dark-outline .icon {
  height: 32px;
  width: 32px;
  background-size: 60%;
  background-position: center center;
  background-repeat: no-repeat;
}

.dark-outline .trash {
  background-image: url('../../Assets/mobile/img/delete_grey.png');
}

.dark-outline .magic {
  background-image: url('../../Assets/mobile/img/magic-wand-grayed.svg');
}

.dark-outline .close, .dark-outline .up, .dark-outline .down  {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-outline .close::after {
  content: "X";
  font-size: 20px;
  font-style: normal;
}

.dark-outline .up::after, .dark-outline .down::after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-left: 2px solid #e5e3e3;
  border-bottom: 2px solid #e5e3e3;
}

.dark-outline .up::after {
  transform: translateY(2px) rotate(135deg);
}

.dark-outline .down::after {
  transform: translateY(-2px) rotate(-45deg);
}
.kecontenta {
  background-color: #514949;
  height: calc(100% - 48px);
}
.kegeneralb {
  transition: transform 0.3s;
  display: flex;
}
.kecontentb {
  padding: 4px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.kerowa {
  display: flex;
  justify-content: center;
  gap: 4px;
}
.keitema {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.keitem.selected {
  box-shadow: 0 0 0 2px #5470e9;
}
.value.selected {
  background: #5470e9;
  color: #fff;
  border: 0;
}
.kecard {
  background-size: 90% 90%;
  background-repeat: no-repeat;
  background-position: center center;
}
.itema {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kerowa.highlighted {
  box-shadow: 0 0 0 3px #7690ff;
}

.kerowa + .kerowa {
  margin-top: 4px;
}
.value {
  font-size: 20px;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  transition: color .5s,background-color .5s;
  border-radius: 4px;
}
.ToTestvalue {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 24px;
  line-height: 1;
  font-family: OpenSansHebrew-Bold;
}
.cvalue {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 24px;
  line-height: 1;
  font-family: OpenSansHebrew-Bold;
}
.item.active .cardp {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 2px;
  left: 2px;
}

.result {
  direction: rtl;
  margin: 8px 4px 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #ccc;
}
.resultitem {
  padding: 0 8px;
  height: 40px;
  color: #333;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color:white
}
.resultitem:nth-child(odd) {
  background-color: #f0f2f9;
}