.general-n {
    /* background-color: #f2f1f1;
    border: 1px solid #ddd; */
    padding: 16px;
    text-align: right;
    border-radius: 8px;
    margin-left: 8px;
    margin-right: 8px;
  }
  .general-i {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .text-s {
    font-family: Arial-Regular;
    text-align: right;
    font-size: 16px;
    padding: 8px;
  }
  
  :global(.mobile) .text-s {
    font-family: OpenSansHebrew-Bold;
  }
  :global(.gb) .text-s {
    text-align: left;
  }
  .general-O {
    /* background-color: #fff;
    border: 1px solid #c3c3c3; */
    border-radius: 5px;
    padding: 2px;
  }
  .content-E {
    border: 3px solid #fff;
    border-radius: 5px;
    /* background-color: #f6f6f6; */
    padding: 8px;
  }
  .header-C {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
  }
  :global(.gb) .header-C {
    flex-direction: row-reverse;
  }
  .actions-j {
    display: flex;
    align-items: center;
  }
  :global(.gb) .actions-j {
    flex-direction: row-reverse;
  }
  .general-g {
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0;;
  }
  .icon-U {
    padding: 2px 8px;
  }
  .icon-k {
    display: block;;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .trash-b {
    height: 24px;
    width: 20px;
    background-image: url('../../Assets/mobile/img/delete_icone.png');
  }
  .magic-m {
    background-image: url('../../Assets/mobile/img/magic-wand.svg');
    background-size: 85%;
    height: 24px;
    width: 24px;
  }
  .shita-container{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
  }
  .shita-row-P {
    display: flex;
    justify-content: center;
    border-radius: 5px;
  }
  .shita-row-P + .shita-row-P {
    margin-left: 7px;
  }
  .row-P {
    display: flex;
    justify-content: center;
    border-radius: 5px;
  }
  .row-P.selected-F {
    box-shadow: 0 0 0 2px #5470e9;
  }
  
  .row-P + .row-P {
    margin-top: 4px;
  }
  .cards-k {
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;
  }
  .shits-cards-k {
    display: flex;
    gap: 8px;
    flex-direction:column;
  }
  .item-c {
    height: 50px;
    width: 40px;
    opacity: 0.7;
    border-radius: 5px;
    color: #000;
    font-size: 22px;
    position: relative;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:aliceblue
  }
  
  .item-c.active {
    opacity: 1;
  }
  .item-c.active .card-T {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 2px;
    left: 2px;
  }
  .card-T {
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .card-R {
    width: 28px;
    height: 28px;
  }
  .card0-f.primary-B {
    background-image: url("../../Assets/mobile/img/spades.svg");
  }
  
  .card1-O.primary-B {
    background-image: url("../../Assets/mobile/img/hearts.svg");
  }
  
  .card2-u.primary-B {
    background-image: url("../../Assets/mobile/img/diamonds.svg");
  }
  
  .card3-C.primary-B {
    background-image: url("../../Assets/mobile/img/clubs.svg");
  }
  
  .card0-f.outline {
    background-image: url("../../Assets/mobile/img/icone-pick.png");
  }
  
  .card1-O.outline {
    background-image: url("../../Assets/mobile/img/icone-chev.png");
  }
  
  .card2-u.outline {
    background-image: url("../../Assets/mobile/img/icone-buba.png");
  }
  
  .card3-C.outline {
    background-image: url("../../Assets/mobile/img/icone-tref.png");
  }
  .value-L {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 20px;
    line-height: 1;
    font-family: OpenSansHebrew-Bold;
  }
  .item-c {
    height: 50px;
    width: 40px;
    opacity: 0.3;
    border-radius: 5px;
    color: #000;
    font-size: 22px;
    position: relative;
    border: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  