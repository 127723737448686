
.wraper{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: seagreen;
}
.wraper h1{
    font-size: 36px;
    text-align: center;
}
.wraper .input-box{
    position: relative;
    height: 50px;
    width: 100%;
    margin: 30px 0;
}
.input-box input{
    width: 100%;
    height: 100%;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .2);
    outline: none;
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder{
    color: #fff;
}

.input-box .icon{
    position: absolute;
    right: 20px;
    top:50%;
    transform: translateY(-50%);
    font-size: 16px;
}
.wraper button{
    width: 100%;
    height: 45px;
    background: #fff;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #333;
    font-weight: 700;
}